import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 4)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export function isValidname(value) {
  var nameRegx = /^[A-Za-z]+$/;
  const isValidName = nameRegx.test(value);
  return isValidName;
}
