// export const baseurl = "http://172.16.1.132:1955/"; //Local
export const baseurl = "https://node-eduverse.mobiloitte.org/"; //staging

let base = `${baseurl}api/v1`;
let nft = `${base}/nft`;
let user = `${base}/user`;
let admin = `${base}/admin`;
let contactUs = `${base}/contactUs`;
let collection = `${base}/collection`;

const Apiconfigs = {
  //API endpoints for user's://
  login: `${user}/login`,
  profile: `${user}/profile`,
  register: `${user}/register`,
  resendOTP: `${user}/resendOTP`,
  verifyOTP: `${user}/verifyOTP`,
  editProfile: `${user}/editProfile`,
  uploadImage: `${user}/uploadImage`,
  connectWallet: `${user}/connectWallet`,
  resetPassword: `${user}/resetPassword`,
  forgotPassword: `${user}/forgotPassword`,
  userSubscribe: `${user}/userSubscribe`,

  //API endpoints for collection://
  viewPlot: `${collection}/viewPlot`,

  //API endpoints for nft://
  addNft: `${nft}/addNft`,
  listNft: `${nft}/listNft`,

  //blogs//
  viewBlogs: `${admin}/viewBlogs`,
  listBlogs: `${admin}/listBlogs`,

  //contactUs
  contactUs: `${contactUs}/contactUs`,
};

export default Apiconfigs;
