import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Logo from "./Logo";
import AnimateLogo from "./AnimateLogo";
import ResetPassword from "src/views/pages/Auth/ResetPassword";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#1B0F20",
    "@media(max-width:600px)": {
      height: "auto",
    },
  },

  earth: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    "@media(max-width:960px)": {
      display: "none",
    },
  },
}));
export default function Reset({ setOpenLogin, setOpenReset, themeSeeting }) {
  const classes = useStyles();
  return (
    <Box className="newModalBorder">
      <Box
        className={
          themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
        }
      >
        <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "logindarkleftModal"
                : "loginlightModal"
            }
          ></div>
          <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "loginDarktModal"
                : "loginlightrightModal"
            }
          ></div>
             <Box style={{ position: "relative", zIndex: "1" }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box className={classes.earth}>
                <img src="images/auth.png" alt="" width="100%" />
                {/* <AnimateLogo /> */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box className={classes.Rightpart}>
                <ResetPassword
                  setOpenLogin={(data) => setOpenLogin(data)}
                  setOpenReset={(data) => setOpenReset(data)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
