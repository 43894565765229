import {
  makeStyles,
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "./Logo";
import { dataPostHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "5px 0px",
    paddingRight: "15px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    "& .acountText": {
      fontSize: "18px",
      "@media(max-width:767px)": {
        fontSize: "14px",
      },
    },
    "& h4": {
      fontSize: "30px",
      marginBottom: "8px",
      // color: "#fff",
      "@media(max-width:767px)": {
        fontSize: "18px",
        // marginBottom: "6px",
        textAlign: "left",
      },
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    "@media(max-width:768px)": {
      paddingRight: "0px",
      // padding: "0px",
      paddingtTop: "30px ",
      paddingtBottom: "30px ",
      height: "100%",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      // color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
      paddingTop: "25px",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      // color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
      background: "none !important",
    },

    "& svg": {
      color: "#693993",
      cursor: "pointer",
    },
  },
  outlineborder1: {
    "& .react-tel-input": {
      marginTop: "6px !important",
    },
    "& .react-tel-input .form-control": {
      width: "100%",
      backgroundColor: "transparent",
      color: "#fff",
      // borderBottom: "1px solid ",
      // borderBottom:"2px solid ${theme.palette.text.borderColor}",
      borderColor: "theme.palette.text.borderColor",
      borderTop: " none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: "0px",
    },
    "& .react-tel-input .country-list .country": {
      padding: "7px 9px",
      textAlign: "left",
      backgroundColor: "#fff",
      color: "#000",
      "&:hover": {
        backgroundColor: "#d9ebf4",
      },
    },
    "& .react-tel-input .selected-flag": {
      backgroundColor: "#202020",
    },
    "& .react-tel-input .selected-flag .arrow": {
      left: "20px",
    },
    "& .react-tel-input .country-list .country.highlight": {
      backgroundColor: "#f1f1f1",
    },
    "& .react-tel-input .selected-flag": {
      "&:hover": {
        backgroundColor: "none",
      },
    },
    "& .react-tel-input .flag-dropdown ": {
      backgroundColor: "transparent",
      borderRight: "1px solid rgba(0, 0, 0, 0.42)",
      border: "none",
      height: "25px",
      position: "absolute",
      top: "5px",
    },
    "& .react-tel-input .flag-dropdown.open .selected-flag": {
      backgroundColor: "#f1f1f1",
    },
  },
}));
const Signup = ({ setSwapPanel }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const [errmsg, setErrMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const formValidationSchema = yep.object().shape({
    firstname: yep
      .string()
      .required("First name is required.")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed.")
      .min(3, "Please enter atleast 3 characters.")
      .max(24, "You can enter only 24 characters."),
    lastname: yep
      .string()
      .required("Last name is required.")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed.")
      .min(3, "Please enter atleast 3 characters.")
      .max(24, "You can enter only 24 characters."),
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),

    mobile: yep
      .string()
      .required("Number is required")
      .max(13, "Please enter a valid number.")
      .min(7, "Please enter a valid number."),

    userRole: yep.string().required("Please select a role type."),
    password: yep
      .string()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Please enter a strong password"
      )
      .min(6, "Please enter atleast 6 characters.")
      .max(16, "You can enter only 16 characters."),
    confirmPassword: yep
      .string()
      .required("Confirm password is required.")
      .oneOf(
        [yep.ref("password"), null],
        "Confirm password or password did not match."
      ),
    countryCode: yep.string().required().min(1).max(4),
  });
  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);

      const response = await dataPostHandler("register", {
        firstName: values.firstname,
        lastName: values.lastname,
        mobileNumber: `${values?.countryCode?.toString()}${values?.mobile?.toString()}`,
        email: values.email,
        userType: values.userRole,
        password: values.password,
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        setIsSubmitting(false);
        toast.success("You have been registered successfully!");
        setSwapPanel(false);

        // history.push("/login");
      } else {
        setErrMsg(response);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };
  window.onload = () => {
    const myInput = document.getElementById("password");
    myInput.oncopy = (e) => e.preventDefault();
  };
  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
      style={{ height: "100%",display: "flex", alignItems: "center" }}
    >
      <Box className={`${classes.mainSignupFrom} noscroll`}>
        <div className="shade13"></div>

        <Container maxWidth="lg" style={{ height: "100%" }}>
          {/* <Box className="logoSection" onClick={() => history.push("/")}>
            <Logo />
          </Box> */}

          {/* <Box className={classes.HeaddingBox}></Box> */}
          <Box  className={`${classes.formikBox} noscroll`}>
            <Box className={classes.Register}>
              <Typography variant="h4" color="primary">
                Create your account
              </Typography>
            </Box>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                email: "",
                mobile: "",
                userRole: "STUDENT",
                password: "",
                confirmPassword: "",
                countryCode: "1",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        type="text"
                        placeholder="First name"
                        variant="standard"
                        fullWidth
                        name="firstname"
                        id="firstname"
                        value={values.firstname}
                        error={Boolean(touched.firstname && errors.firstname)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />

                      <FormHelperText error className={classes.helperText}>
                        {touched.firstname && errors.firstname}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        type="text"
                        placeholder="Last name"
                        variant="standard"
                        fullWidth
                        name="lastname"
                        id="lastname"
                        value={values.lastname}
                        error={Boolean(touched.lastname && errors.lastname)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />

                      <FormHelperText error className={classes.helperText}>
                        {touched.lastname && errors.lastname}
                      </FormHelperText>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Select
                        className={classes.phoneTextField}
                        value={values.selectcountry}
                        name="selectcountry"
                        error={Boolean(
                          touched.selectcountry && errors.selectcountry
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        style={{ textAlign: "left", color: "#ccc" }}
                      >
                        <MenuItem value={""} disabled={true}>
                          Select Country
                        </MenuItem>
                        {countryListData &&
                          countryListData.map((data, index) => {
                            return (
                              <MenuItem
                                value={data.country}
                                key={index}
                                onClick={() => setSelectedCode(data.code)}
                                style={{ paddingLeft: "20px" }}
                              >
                                {data.country}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText error className={classes.helperText}>
                        {touched.selectcountry && errors.selectcountry}
                      </FormHelperText>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        type="email"
                        placeholder="Email address"
                        fullWidth
                        name="email"
                        id="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />

                      <FormHelperText error className={classes.helperText}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box width="100%" display="flex" alignItems="center">
                        <Box style={{ width: "45px", marginRight: "2px" }}>
                          <TextField
                            type="number"
                            name="countryCode"
                            id="countryCode"
                            value={values.countryCode}
                            error={Boolean(
                              touched.countryCode && errors.countryCode
                            )}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              if (e.target.value.length <= 4) {
                                handleChange(e);
                              }
                            }}
                            className={classes.FormInputField}
                            onKeyPress={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      marginRight: "-10px",
                                    }}
                                  >
                                    +
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box style={{ width: "calc(100% - 45px)" }}>
                          <TextField
                            type="number"
                            placeholder="Enter your mobile no."
                            fullWidth
                            name="mobile"
                            id="mobile"
                            value={values.mobile}
                            error={Boolean(touched.mobile && errors.mobile)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={classes.FormInputField}
                            onKeyPress={(event) => {
                              if (
                                event?.key === "-" ||
                                event?.key === "+" ||
                                event?.key === "."
                              ) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <FormHelperText error className={classes.helperText}>
                        {touched.mobile && errors.mobile}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Select
                        name="userRole"
                        id="userRole"
                        value={values.userRole}
                        error={Boolean(touched.userRole && errors.userRole)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{ textAlign: "left", color: "#ccc" }}
                        fullWidth
                      >
                        <MenuItem value="all" disabled={true}>
                          &nbsp; Select role
                        </MenuItem>
                        {/* <MenuItem value="USER">&nbsp; User</MenuItem> */}
                        <MenuItem value="STUDENT">&nbsp; Student</MenuItem>
                        <MenuItem value="INSTITUTE">&nbsp; Institute</MenuItem>
                      </Select>

                      <FormHelperText error className={classes.helperText}>
                        {touched.userRole && errors.userRole}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        variant="standard"
                        fullWidth
                        name="password"
                        id="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword ? (
                                    <VisibilityIcon
                                      className={classes.iconsclass1}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      className={classes.iconsclass1}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type={showPassword1 ? "text" : "password"}
                        placeholder="Confirm Password"
                        variant="standard"
                        fullWidth
                        name="confirmPassword"
                        id="confirmPassword"
                        value={values.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword1(!showPassword1)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword1 ? (
                                    <VisibilityIcon
                                      className={classes.iconsclass1}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      className={classes.iconsclass1}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.confirmPassword && errors.confirmPassword}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box mb={2} mt={1}>
                        {errmsg !== "" && (
                          <FormHelperText error>{errmsg}</FormHelperText>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: "2px" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              Please wait... <ButtonCircularProgress />
                            </>
                          ) : (
                            "Register"
                          )}
                        </Button>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="h6"
                          className="acountText"
                          style={{
                            // color: "#fff",
                            marginRight: "5px",
                          }}
                        >
                          Already have an account?
                        </Typography>
                        <Typography
                          variant="h6"
                          className="acountText"
                          style={{
                            textAlign: "center",
                            // color: "#fff",

                            cursor: "pointer",
                          }}
                          onClick={() => setSwapPanel(false)}
                        >
                          Login
                        </Typography>
                      </Box>

                      <div className="shade14"></div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Signup;
