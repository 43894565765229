import {
  makeStyles,
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import { patchAPIHandler, putAPIHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "30px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    "& h4": {
      fontSize: "30px",
      marginBottom: "24px",
      color: "#fff",
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
      background: "none !important",
    },

    "& svg": {
      color: "#693993",
      cursor: "pointer",
    },
  },
}));

export default function VerifyOTP({ setVerify, setOpenReset }) {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const [succssMsg, setSuccssMsg] = useState("");
  const [errmsg, setErrMsg] = useState("");
  const formInitialSchema = {
    otp: "",
  };
  const formValidationSchema = yep.object().shape({
    otp: yep
      .string()
      .required("Enter your OTP.")
      .min(4, "Please enter a valid OTP.")
      .max(4, "Please enter a valid OTP."),
  });
  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await patchAPIHandler("verifyOTP", {
        email: window.localStorage.getItem("email"),
        otp: Number(values.otp),
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        if (response.data.statusCode == 200) {
          console.log("response0000", response);
          setVerify(false);
          setOpenReset(true);
          toast.success(response.data.responseMessage);
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setErrMsg(response);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };
  const resendOTPAPIHandler = async () => {
    try {
      setSuccssMsg("");
      setIsResendingOTP(true);
      const response = await putAPIHandler("resendOTP", {
        email: window.localStorage.getItem("email"),
      });
      if (response) {
        console.log("responseOTp", response);
        setSuccssMsg("OTP has been sent on your registered email.");
        setIsResendingOTP(false);
      } else {
        setIsResendingOTP(false);
      }
    } catch (error) {
      console.log(error);
      setIsResendingOTP(false);
    }
  };
  useEffect(() => {
    if (succssMsg !== "") {
      const timer = setTimeout(() => {
        setSuccssMsg("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [succssMsg]);
  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
    >
      <div
        className={
          themeSeeting?.settings?.theme === "DARK"
            ? "logindarkleftModal"
            : "loginlightModal"
        }
      ></div>
      <div
        className={
          themeSeeting?.settings?.theme === "DARK"
            ? "loginDarktModal"
            : "loginlightrightModal"
        }
      ></div>
      <Box className={classes.mainSignupFrom} style={{ position: "relative", zIndex: "1" }}>
        <div className="shade13"></div>
        <Box>
          <IconButton className="closeIcon" onClick={() => setVerify(false)} style={{
            position: "absolute",
            top: "-32px",
            right: "0",
            zIndex: "9"
          }}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
        <Container maxWidth="lg">
          {/* <Box className="logoSection" onClick={() => history.push("/")}>
            <Logo />
          </Box> */}
          <Box className={classes.Register}>
            <Typography variant="h4" color="primary">
              Verify OTP
            </Typography>
          </Box>

          <Box className={classes.HeaddingBox}></Box>
          <Box className={classes.formikBox}>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type="number"
                        placeholder="Enter your OTP"
                        fullWidth
                        name="otp"
                        id="otp"
                        value={values.otp}
                        error={Boolean(touched.otp && errors.otp)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />

                      <FormHelperText error className={classes.helperText}>
                        {touched.otp && errors.otp}
                      </FormHelperText>
                    </Grid>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      width="100%"
                      padding="0px 10px"
                    >
                      <Box width="fit-content" onClick={resendOTPAPIHandler}>
                        <Button
                          disabled={isResendingOTP}
                        // variant="h6"
                        // style={{ fontSize: "12px", cursor: "pointer" }}
                        >
                          Resend OTP
                        </Button>
                      </Box>
                    </Box>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box mb={2} mt={2}>
                        {errmsg !== "" && (
                          <FormHelperText error>{errmsg}</FormHelperText>
                        )}
                        {succssMsg !== "" && (
                          <FormHelperText style={{ color: "green" }}>
                            {succssMsg}
                          </FormHelperText>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: "2px" }}
                          disabled={isSubmitting || isResendingOTP}
                        >
                          {isSubmitting ? (
                            <>
                              Please wait... <ButtonCircularProgress />
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Box>
                      {/* <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        flexDirection="column"
                      >
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",

                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() =>{setVerify(false)
                            setOpenReset(true);}}
                        >
                          Back
                        </Typography>
                      </Box> */}

                      <div className="shade14"></div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
