import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { toast } from "react-toastify";
import { ACTIVE_NETWORK } from "src/constants";
import Web3 from "web3";
import { dataPostHandler, getDataHandlerAPI } from "src/apiConfig/service";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    localStorage.setItem("userAddress", userAddress);
  } else {
    localStorage.removeItem("userAddress");
  }
};
function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const { activate, account, deactivate, chainId, library } = useWeb3React();
  const [userData, setUserData] = useState({});
  const [isUserAlreadyRegistered, setIsUserAlreadyRegistered] = useState(false);
  const [isMetaMaskOpen, setIsOpenMetaMaskOpen] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [openRegister, setOpenRegister] = useState(false);
  const [blogsList, setBlogsList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const connectWalletHandler = (data) => {
    try {
      localStorage.setItem("walletName", data?.name);
      const connector = data?.connector;
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error(JSON.stringify(error.message));
    }
  };
  const connectWalletAPIHandler = async (account) => {
    try {
      const response = await dataPostHandler("connectWallet", {
        walletAddress: account,
      });
      if (response) {
        // window.localStorage.setItem("token", response?.token);
        getUserProfileDataHandler(window.localStorage.getItem("token"));
        // if (response?.isRegister) {
        //   setIsUserAlreadyRegistered(false);
        // } else {
        //   setIsUserAlreadyRegistered(true);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserProfileDataHandler = async (token) => {
    try {
      const response = await getDataHandlerAPI("profile", token);
      if (response) {
        console.log("reponse---profile", response);
        setUserData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const disconnectWalletHandler = async () => {
    try {
      // deactivate();
      toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };
  const getUserbalce = async () => {
    setYourWalletBalance("");
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const getBlogsAPIHandler = async () => {
    try {
      setBlogsList([]);
      const response = await axios({
        method: "GET",
        url: Apiconfigs.listBlogs,
        params: {
          search: searchKey !== "" ? searchKey : null,
        },
      });
      if (response) {
        setBlogsList(response?.data?.result?.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBlogsAPIHandler();
  }, [searchKey]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    openRegister,
    isMetaMaskOpen,
    yourWalletBalance,
    isUserAlreadyRegistered,
    blogsList,
    searchKey,
    updateUser: (account) => {
      setSession(account);
    },
    setOpenRegister: (data) => setOpenRegister(data),
    checkLogin: () => checkLogin(),
    setSearchKey: (data) => setSearchKey(data),
    connectWallet: (data) => connectWalletHandler(data),
    disconnectWallet: () => disconnectWalletHandler(),
    connectWalletAPIHandler: (data) => connectWalletAPIHandler(data),
    setIsOpenMetaMaskOpen: (data) => setIsOpenMetaMaskOpen(data),
    getUserProfileDataHandler: (data) => getUserProfileDataHandler(data),
  };
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      connectWalletAPIHandler();
    }
  }, [window.localStorage.getItem("token")]);

  useEffect(() => {
    if (account) {
      getUserbalce();
      // connectWalletAPIHandler(account);
    }
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]);
  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        // addNetworkHandler();
      }
    }
  };
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
