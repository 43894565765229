import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/register",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Register")),
  },
  {
    exact: true,
    path: "/login",
    layout: HomeLayout,
    component: lazy(() => import("src/component/MainLogin")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Forgot")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Verify")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Reset")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ProfileDetails/Profile")),
  },
  // {
  //   exact: true,
  //   path: "/loader",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/component/PageLoading")),
  // },
  {
    exact: true,
    path: "/plot",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Plots/Index")),
  },
  {
    exact: true,
    path: "/upload-profile",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/ProfileDetails/UploadProfile")
    ),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/ContactUs")),
  },

  {
    exact: true,
    path: "/term",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/TermCondition")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/AboutUs")),
  },
  {
    exact: true,
    path: "/live-class",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LiveClass/ClassRoom")),
  },
  {
    exact: true,
    path: "/classroom-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LiveClass/ClassRoomDetails")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/plot-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/PlotDeatils")),
  },
  {
    exact: true,
    path: "/detail-page",
    layout: HomeLayout,
    component: lazy(() => import("src/component/DetailPage")),
  },
  {
    exact: true,
    path: "/institute",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SetupInstitute/Institute")),
  },
  {
    exact: true,
    path: "/blog-list",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Blog")),
  },
  {
    exact: true,
    path: "/institute-details",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/SetupInstitute/InstituteDetails")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
